#testimonials .testimonials_box{
    display: flex;
    flex-direction: row;
    align-content: center;
    position: relative;
    justify-content: space-around;
}
#testimonials .testimonials_box button{
    position: absolute;
    width: 44px;
    height: 44px;
    right: 0;
    background-color: #161616;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
    border-width: 2px;
    border-style: solid;
    border-image-slice: 1;
    box-sizing: border-box;
}
#testimonials .testimonials_box button.prev{
    bottom: 80px;
    z-index: 20;
    -webkit-transition:all 0.25s linear;
    -moz-transition:all 0.25s linear;
    -o-transition:all 0.25s linear;
    -ms-transition:all 0.25s linear;
    transition:all 0.25s linear;
}
#testimonials .testimonials_box button.prev:after{
    content: "";
    position: absolute;
    width: 3px;
    height: 16px;
    bottom: 7px;
    right: 20px;
    transform: rotate(-45deg);
    background-color: #fff;
}
#testimonials .testimonials_box button.prev:before{
    content: "";
    position: absolute;
    width: 3px;
    height: 16px;
    top: 7px;
    right: 20px;
    transform: rotate(45deg);
    background-color: #fff;
}
#testimonials .testimonials_box button.next{
    bottom: 30px;
    z-index: 20;
    -webkit-transition:all 0.25s linear;
    -moz-transition:all 0.25s linear;
    -o-transition:all 0.25s linear;
    -ms-transition:all 0.25s linear;
    transition:all 0.25s linear;
}
#testimonials .testimonials_box button.next:after{
    content: "";
    position: absolute;
    width: 3px;
    height: 16px;
    bottom: 7px;
    left: 20px;
    transform: rotate(45deg);
    background-color: #fff;
}
#testimonials .testimonials_box button.next:before{
    content: "";
    position: absolute;
    width: 3px;
    height: 16px;
    top: 7px;
    left: 20px;
    transform: rotate(-45deg);
    background-color: #fff;
}
#testimonials .testimonials_slide{
    padding: 20px;
    width: 100%;
    color: #fff;
    position: relative;
}
#testimonials .testimonials_slide .ts{
    background-color: #161616;
    padding: 30px 20px;
    overflow: hidden;
    position: relative;
}
#testimonials .testimonials_slide .ts .testimonials_overlay{
    position: absolute;
    top: 0;
    left: -5%;
    font-size: 140px;
    line-height: 120px;
    color: #000;
    text-transform: uppercase;
    font-family: "Magistral";
    z-index: 0;
    opacity: 0.3;
}
.testimonials_slide{
    display: none ;
}
.testimonials_slide:first-child{
    display: block ;
}
.testimonials_slide{
    display: block ;
}
#testimonials .testimonials_slide .testimonials_header{
    position: relative;
    z-index: 5;
    display: block;
}
#testimonials .testimonials_slide .testimonials_header img.person{
    position: absolute;
    max-width: 70px;
    top: 0px;
    left: 20px;
    display: inline-block;
}
#testimonials .testimonials_slide .testimonials_header h3{
    text-transform: uppercase;
    font-family: "Magistral";
    font-size: 24px;
    line-height: 1.2;
    margin: 10px 10px 10px 120px;
    display: block;
    min-height: 80px;
    max-width: 200px;
}
#testimonials .testimonials_slide .testimonials_header img.stars{
    max-width: 120px;
    position: absolute;
    bottom: -10px;
    margin: 0 0 0 120px;
}
#testimonials .testimonials_slide .reviewbody{
    margin-bottom: 20px;
}
#testimonials .testimonials_slide p{
    color: #fff;
    margin-top: 20px;
    padding: 20px 15px 0;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    z-index: 5;
    text-align: left;
}
#testimonials .testimonials_link a.btn_gr{
    position: relative;
    margin: 0 auto;
    color: #161616;
}
@media only screen and (max-width:1600px){
    #testimonials .testimonials_slide {
        padding: 10px;
    }
    #testimonials .testimonials_slide .ts {
        padding: 30px 20px;
    }
    #testimonials .testimonials_slide .testimonials_header img.person {
        max-width: 50px;
        position: absolute;
        top: 0px;
        left: 20px;
    }
    #testimonials .testimonials_slide .testimonials_header h3 {
        font-size: 20px;
        line-height: 1.1;
        margin: 10px 0 0 100px;
        min-height: 60px;
        max-width: 120px;
    }
    #testimonials .testimonials_slide .testimonials_header img.stars {
        max-width: 80px;
        position: absolute;
        left: 100px;
        margin: 0;
    }
    #testimonials .testimonials_slide p {
        color: #fff;
        margin-top: 10px;
        padding: 20px 10px 0;
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
    }
    #testimonials .testimonials_box button{
        right: -10px;
    }
}
@media only screen and (max-width:1023px){
    #testimonials .testimonials_slide {
        padding: 10px;
    }
    #testimonials .testimonials_slide .ts {
        padding: 20px 20px;
    }
    #testimonials .testimonials_slide .testimonials_header img.person {
        max-width: 50px;
    }
    #testimonials .testimonials_slide .testimonials_header h3 {
        font-size: 18px;
        line-height: 20px;
    }
    #testimonials .testimonials_slide .testimonials_header img.stars {
        max-width: 100px;
    }
    #testimonials .testimonials_slide p {
        padding: 20px 10px 0;
        line-height: 22px;
    }
}