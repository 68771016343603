.alarm_acordion .slider-container__slider2 {
  opacity: 0;
  position: absolute !important;
}
.alarm_acordion .slider-container__slider2.active {
  position: relative !important;
  opacity: 1;
}
#tabs {
  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.slider-container .slider-container__tab-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
  line-height: 60px;
  font-family: "Magistral";
  font-size: 24px;
  text-transform: uppercase;
  outline: none;
  transition: all 0.75s;
}
.slider-container .slider-container__tab-item:nth-child(1):after{
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fefdfe;
  top: 0;
  right: 0;
  z-index: 10;
}
.slider-container .slider-container__tab-item {
  background-color: #161616;
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.slider-container .slider-container__tab-item span{
  background:  #fff;
  color: #fff;
  display: block;
  line-height: 1.2;
  font-size: 18px;
  transition: all 0.75s;
  -webkit-background-clip: text;
}
.slider-container .slider-container__tab-item_active span {
  color: #fe0000;
  transition: all 0.75s;
  -webkit-background-clip: text;
}

#tabs li:nth-child(1):after{
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fefdfe;
  top: 0;
  right: 0;
  z-index: 10;
}
#tabs li a span{
  font-family: "Magistral";
  font-size: 24px;
  text-transform: uppercase;
  display: block;
  color: #fe0000;
  
}
#tabs li a.inactive span {
  background: #fff;
  color: #fff;
}


.tab_wrap {
  background-color: #000;
  background-position: center;
  background-size: cover;
  padding: 80px 100px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}

.tab_wrap .slick-slider.active button.slick-arrow{
  border: none;
  background-color: #161616;
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: -105px;
  z-index: 100;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
}
.tab_wrap .slick-slider.active button.slick-arrow.prev{
  right: -20px;
}
.tab_wrap .slick-slider.active button.slick-arrow.prev:after{
  content: "";
  position: absolute;
  width: 3px;
  height: 23px;
  background-color: #fff;
  transform: rotate(45deg);
  top: 6px;
}
.tab_wrap .slick-slider.active button.slick-arrow.prev:before{
  content: "";
  position: absolute;
  width: 3px;
  height: 23px;
  background-color: #fff;
  transform: rotate(-45deg);
  bottom: 6px;
}
.tab_wrap .slick-slider.active button.slick-arrow.next{
  right: -80px;
}
.tab_wrap .slick-slider.active button.slick-arrow.next:after{
  content: "";
  position: absolute;
  width: 3px;
  height: 23px;
  background-color: #fff;
  transform: rotate(-45deg);
  top: 6px;
}
.tab_wrap .slick-slider.active button.slick-arrow.next:before{
  content: "";
  position: absolute;
  width: 3px;
  height: 23px;
  background-color: #fff;
  transform: rotate(45deg);
  bottom: 6px;
}
.tab_wrap .tab_wrapper{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin:30px 0;
  flex-wrap: nowrap;
}
.tab_wrap.tab_second .tab_wrapper{
  flex-direction: row-reverse;
}
.tab_wrap{
  position: relative;
}
.tab_wrap .tab_slide .tab_title{
  font-size: 38px;
  text-transform: uppercase;
  font-family: "Magistral";
  color: #fff;
  padding: 0;
  margin: 0;
}
.tab_wrap .tab_left{
  padding: 0 50px;
}
.tab_wrap .tab_left ul li{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  height: 60px;
}
.tab_wrap .tab_left ul li img{
  max-width: 45px;
}
.tab_wrap .tab_left ul li span{
  color: #fff;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 20px;
}
.tab_wrap .tab_right img{
  width: 100%;
  max-width: 450px;
}
.tab_wrap .tab_footer{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.tab_wrap.tab_second .tab_footer{
  flex-direction: row-reverse;
}
.tab_wrap .tab_footer .tab_price{
  color: #fff;
  text-align: right;
  min-height: 65px;
}
.tab_wrap .tab_footer .tab_price .price_b{
  display: block;
  font-family: "Magistral_m";
  text-transform: uppercase;
  font-size: 22px;
}
.tab_wrap .tab_footer .tab_price .price_b span{
  display: inline-block;
  font-size: 34px;
}
.tab_wrap .tab_footer .tab_price .price_s{
  display: block;
  font-family: "Magistral_m";
  text-transform: uppercase;
  font-size: 18px;
}
.tab_slide{
  position: relative;
}

/*
.tab_slide a.modal-youtube{
  display: block;
  max-width: 120px;
  position: absolute;
  right: 50px;
  top: 50px;
}
*/




@media only screen and (max-width:1600px){
  .tab_wrap {
      padding: 60px 100px;
  }
  .tab_wrap .tab_footer .tab_price .price_b {
      font-size: 20px;
  }
  .tab_wrap .tab_wrapper {
      margin: 0;
  }
  .tab_wrap .tab_slide .tab_title {
      font-size: 32px;
  }
  .tab_wrap .tab_footer .tab_price .price_b span {
      font-size: 32px;
  }
  .tab_wrap .tab_left ul li {
      height: 40px;
  }
  .tab_wrap .tab_left ul li span {
      font-size: 14px;
  }
  .tab_wrap .tab_left ul li img {
      max-width: 30px;
  }
  .tab_wrap .tab_right img {
      max-width: 350px;
  }
  .tab_wrap .slick-slider.active button.slick-arrow {
      bottom: -80px;
  }
  .slick-slider.active button.slick-arrow.prev {
      right: -30px;
  }
  .slick-slider.active button.slick-arrow.next {
      right: 30px;
  }
}
@media only screen and (max-width:1200px){
  .tab_wrap {
      padding: 80px 50px;
  }
  .tab_wrap .tab_slide .tab_title {
      font-size: 40px;
  }
  .tab_wrap .slick-slider.active button.slick-arrow {
      bottom: -100px;
  }
  .tab_wrap .slick-slider.active button.slick-arrow.prev{
      right: 60px;
  }
  .tab_wrap .slick-slider.active button.slick-arrow.next{
      right: 0px;
  }
  .tab_slide a.modal-youtube{
      max-width: 100px;
      right: 50px;
      top: 0px;
  }
}